import { useForm } from 'react-hook-form'

import {
  Navigation,
  OptIn,
  Input,
  If
} from '@itau-loans-www/shopping/src/components'
import { useLead } from '@itau-loans-www/shopping/src/hooks'
import ProposalLayout from 'layouts/Proposal'

import { useBasicData } from './useBasicData'

const BasicData = ({ pageContext }) => {
  const {
    leadData: { name, email, cpf, phone }
  } = useLead()

  const initialValues = {
    name: name ?? '',
    email: email ?? '',
    cpf: cpf ?? '',
    phone: phone ?? ''
  }

  const {
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues
  })
  const hasDocument = !!cpf

  const { onSubmit, isButtonLoading, isProd } = useBasicData({
    hasDocument
  })

  return (
    <ProposalLayout pageContext={pageContext}>
      <h1 className="proposal-title">Para continuar, insira os seus dados</h1>
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <Input.Cpf
          label="CPF"
          id="cpf"
          data-cy="cy-input-cpf"
          name="cpf"
          autoComplete="on"
          control={control}
        />
        <If
          condition={isProd}
          renderIf={
            <Input.Name
              label="Nome completo"
              data-cy="cy-input-name"
              id="name"
              name="name"
              autoComplete="on"
              control={control}
            />
          }
          renderElse={
            <Input.Text
              label="Nome completo"
              data-cy="cy-input-name"
              id="name"
              name="name"
              autoComplete="on"
              control={control}
            />
          }
        />

        <Input.Email
          label="E-mail"
          id="email"
          data-cy="cy-input-email"
          name="email"
          autoComplete="on"
          control={control}
        />
        <Input.Cellphone
          label="Celular"
          id="phone"
          data-cy="cy-input-phone"
          name="phone"
          mask="phone"
          autoComplete="on"
          control={control}
        />
        <OptIn />
        <Navigation
          submit
          nextStep
          isNextButtonDisabled={!isValid}
          isNextStepButtonLoading={isButtonLoading}
          nextButtonId="basic-data-next-btn"
          dataCy="cy-btn-next"
          nextButtonText="Continuar simulação"
        />
      </form>
    </ProposalLayout>
  )
}

export default BasicData
