import { useState, useEffect, useCallback } from 'react'

import { BUILD_ENV } from 'gatsby-env-variables'

import { useLead, useProposal } from '@itau-loans-www/shopping/src/hooks'
import { http } from '@itau-loans-www/shopping/src/services'
import analytics from '@mobi/libraries/analytics'
import { goTo, cleanWhitespaces } from 'utils'

export const useBasicData = ({ hasDocument }) => {
  const { cleanProduct } = useProposal()
  const [isButtonLoading, setButtonLoading] = useState(false)

  // const { getRecaptcha } = useRecaptcha(setButtonLoading)

  const { changeOffers, changeLeadData } = useLead()

  // env verification
  const isProd = BUILD_ENV === 'production'

  const cleanOlderProposalData = useCallback(() => {
    changeLeadData({
      previousStep: 'home',
      vwoVariant: 'A'
    })

    if (hasDocument) {
      return
    }

    http.cleanAuth()
    cleanProduct()
    changeOffers({
      hero: undefined,
      others: undefined,
      desiredLoanValue: undefined
    })
  }, [changeOffers, changeLeadData, cleanProduct, hasDocument])

  useEffect(() => {
    cleanOlderProposalData()
  }, [cleanOlderProposalData])

  useEffect(() => {
    analytics.track('pageLoad', { currentPage: 'formulario-4-campos-basicos' })
  }, [])

  const onSubmit = async ({ name, email, cpf, phone }) => {
    setButtonLoading(true)

    // const recaptcha = await getRecaptcha()

    const formData = {
      cpf,
      name: cleanWhitespaces(name),
      email,
      phone
      // recaptcha,
    }

    analytics.track('buttonClick', {
      currentPage: 'formulario-4-campos-basicos',
      detail: 'click-step-basic'
    })

    return http.proposal
      .newCreateLead(formData)
      .then(({ next_step }) => {
        changeLeadData({
          name,
          email,
          cpf,
          phone
        })

        if (next_step === 'desired-loan-data') {
          changeLeadData({ variant: 'A' })
        }

        goTo(next_step)
      })
      .catch((err) => {
        console.log({ err })
        setButtonLoading(false)
      })
  }

  // TO DO: testar handles de erro com os cenários:
  // - ocultar o recaptcha
  // - colocar o cleanWhitespaces como export default

  return {
    onSubmit,
    isButtonLoading,
    isProd
  }
}
